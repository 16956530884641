import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div id="headline">
          <h1 style={{display: 'flex', alignItems: 'center'}}>
              <div style={{ marginRight: 8, marginTop: 8}}><div style={{display: 'flex', transform: 'rotate(90deg)', alignItems: 'center'}}>α</div></div>
              <span style={{color: '#ff8c00'}}>six</span><span style={{color: '#333'}}>/</span><span style={{color: '#1a00ff'}}>eighteen</span></h1>
      </div>
      <div id="about" style={{marginTop: 32, marginLeft: 16, marginRight: 16, maxWidth: 800, textAlign: 'center'}}>
        <code>sixeighteen is an investment and r&d group from MIT focused on cryptocurrency and blockchain technologies. we invest in and trade cryptocurrencies of all sizes and develop new technologies and protocols in-house.</code>
      </div>
      <div id="contact" style={{marginTop: 48, opacity: 0.6}}>
          <a href="mailto:hello@six-eighteen.com"><code>hello@six-eighteen.com</code></a>
      </div>
      {/*<header className="App-header">*/}
        {/*<img src={logo} className="App-logo" alt="logo" />*/}
        {/*<p>*/}
          {/*Edit <code>src/App.js</code> and save to reload.*/}
        {/*</p>*/}
        {/*<a*/}
          {/*className="App-link"*/}
          {/*href="https://reactjs.org"*/}
          {/*target="_blank"*/}
          {/*rel="noopener noreferrer"*/}
        {/*>*/}
          {/*Learn React*/}
        {/*</a>*/}
      {/*</header>*/}
    </div>
  );
}

export default App;
